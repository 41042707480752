import { createTheme } from "@mui/material/styles";

const color = {
  main: "#FF4094",
  light: '#FCE4EC',
  secondary: "#FFFFFF",
}

const mainTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 735,
      lg: 900,
      xl: 1080,
    },
  },
  palette: {
    primary: {
      main:  color.main,
      light: color.light,
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: color.secondary,
      contrastText: "#000000",
    },
    text: {
      primary: '#757575',
      secondary: 'rgba(0,0,0,0.60)',
      disabled: 'rgba(0,0,0,0.38)',
    },
  },
  typography: {
    allVariants: {
      fontFamily: `'M PLUS Rounded 1c', 'Noto Sans JP', sans-serif`,
    },
    body1: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: '0.1px',
    },
    body2: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '0.17px',
      color: 'rgba(0, 0, 0, 0.60)',
    },
    subtitle1: {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '0.1px',
    },
    subtitle2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '0.1px',
    },
    caption: {
      fontFamily: "Noto Sans JP",
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 1.2,
      color: '#FFFFFF',
      letterSpacing: '0.1px',
    },
    button: {
      textTransform: 'none',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '26px',
      letterSpacing: '0.46px',
    },
    h2: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '1.2',
    },
    h3: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '1.2',
      color: 'rgba(0, 0, 0, 0.64)',
    },
    h6: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: '0.15px',
    },
  }
});

export { mainTheme, color };
