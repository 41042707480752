import { ButtonBase, Typography } from "@mui/material"

const TrackingButtonInsta = () => {
  return (
    <ButtonBase
      component="a"
      href="https://www.instagram.com/ocha_tsubame_dentalclinic?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
      target="_blank"
      sx={{
        zIndex: 100,
        top: '130px',
        right: '10px',
        position: 'fixed',
        writingMode: 'vertical-rl',
        textOrientation: 'upright',
        background: '#283377',
        color: '#FFFFFF',
        borderRadius: '36px',
        padding: '12px 8px',
        display: {
          xs: 'none',
          lg: 'inline-block',
        }
      }}
    >
      <Typography
        color="white"
        fontSize="20px"
      >
        最新情報は
        <span
          style={{
            textOrientation: 'mixed',
          }}
        >Instagram</span>
        にて
      </Typography>
    </ButtonBase>
  )
}

export default TrackingButtonInsta