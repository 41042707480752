import { ButtonBase, Typography } from "@mui/material"

const TrackingButtonYoyaku = () => {
  return (
    <ButtonBase
      component="a"
      href="https://clinics-app.com/dental/c2be2993-6582-40bf-ba37-bcdcf6ff3ee2/exclusive_reservation"
      target="_blank"
      sx={{
        zIndex: 100,
        top: '450px',
        right: '10px',
        position: 'fixed',
        writingMode: 'vertical-rl',
        textOrientation: 'upright',
        background: '#283377',
        color: '#FFFFFF',
        borderRadius: '36px',
        padding: '12px 8px',
        display: {
          xs: 'none',
          lg: 'inline-block',
        }
      }}
    >
      <Typography
        color="white"
        fontSize="20px"
      >
        24時間Web予約受付中
      </Typography>
    </ButtonBase>
  )
}

export default TrackingButtonYoyaku