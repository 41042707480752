import { Box, Card, Stack, Typography } from "@mui/material";
import { Calendar } from "@/components/ui/Calendar";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import EventNoteIcon from '@mui/icons-material/EventNote';

const Information = () => {

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundImage: 'url(/background01.jpg)',
        backgroundSize: 'cover',
      }}
    >


      <Stack
        maxWidth="1200px"
        display="flex"
        gap={3}
        padding={3}
      >

        <Card
          sx={{
            margin: "0 auto",
            padding: "16px 8px",
            textAlign: "center",
            width: {
              xs: '100%',
              md: "750px",
            },
            border: "solid 2px rgb(150, 155, 178)",
            borderRadius: '16px',
          }}
        >
          <Stack
            gap="10px"
          >
            <Typography
              color="#757575"
              sx={{
                fontSize: {
                  xs: '20px',
                  md: '29px',
                },
              }}
            >
              Information
            </Typography>
            <Typography
              color="#757575"
              sx={{
                fontSize: {
                  xs: '16px',
                  md: '20px',
                },
              }}
            >
              [御茶ノ水つばめ歯科・矯正歯科]<br />
              御茶ノ水駅、神保町駅から徒歩5分の歯医者さん。<br />
              診療時間を長めに取り、一人ひとりを丁寧に診ています。<br />
              虫歯治療に矯正治療、どちらも行えます。<br />
            </Typography>
          </Stack>
        </Card>

        <Stack
          gap="30px"
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row',
            }
          }}
        >
          <Stack
            gap="20px"
            sx={{
              width: {
                xs: '100%',
                md: "50%",
              },
            }}
          >
            <Calendar
              id="medical_hours"
            />

            <Box
              sx={{
                borderRadius: '41px',
              }}
            >
              <img
                src="/map.png"
                alt="map"
                style={{
                  width: '100%',
                  display: 'inline-block',
                  borderRadius: '41px',
                }}
              />
            </Box>
          </Stack>

          <Stack
            gap="20px"
            sx={{
              width: {
                xs: '100%',
                md: "50%",
              },
            }}
          >
            <Stack
              width="100%"
              display="flex"
              gap={3}
              alignItems="start"

              sx={{
                margin: "0 auto",
                padding: "16px 8px",
                border: "solid 2px rgb(150, 155, 178)",
                borderRadius: '16px',
                background: '#FFFFFF',
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: '#616161',
                    borderRadius: '50%',
                    width: '50px',
                    minWidth: '50px',
                    height: '50px',
                  }}
                >
                  <FmdGoodIcon
                    sx={{
                      color: '#FFFFFF',
                      lineHeight: 1,
                      fontSize: '20px',
                    }}
                  />
                </Box>

                <Typography
                  variant="body1"
                  sx={{
                    lineHeight: 1.5,
                    fontSize: {
                      xs: '14px',
                      md: '16px',
                    },
                  }}
                >
                  〒101-0052<br />
                  東京都千代田区神田小川町3丁目8-10<br />
                  メアリヒト御茶ノ水ビル1階
                </Typography>

              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: '#616161',
                    borderRadius: '50%',
                    width: '50px',
                    minWidth: '50px',
                    height: '50px',
                  }}
                >
                  <DirectionsTransitIcon
                    sx={{
                      color: '#FFFFFF',
                      lineHeight: 1,
                      fontSize: '20px',
                    }}
                  />
                </Box>

                <Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      lineHeight: 1.5,
                      fontSize: {
                        xs: '14px',
                        md: '16px',
                      },
                    }}
                  >
                    ・JR御茶ノ水駅 御茶ノ水橋口改札 徒歩6分<br />
                    ・千代田線 新御茶ノ水駅B3b 徒歩5分<br />
                    ・神保町駅(半蔵門線、都営新宿線、都営三田線)A5 徒歩6分<br />
                    ・都営新宿線 小川町駅、丸の内線 淡路町駅B5 徒歩6分<br />
                    ・東西線 竹橋駅 3a 徒歩10分
                  </Typography>
                </Stack>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={2}
                width="100%"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: '#616161',
                    borderRadius: '50%',
                    width: '50px',
                    minWidth: '50px',
                    height: '50px',
                  }}
                >
                  <PhonelinkRingIcon
                    sx={{
                      color: '#FFFFFF',
                      lineHeight: 1,
                      fontSize: '20px',
                    }}
                  />
                </Box>

                <Stack
                  width="100%"
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    gap={1.5}
                  >

                    <Stack
                      display="flex"
                      justifyContent="center"
                      gap={1}
                      alignItems="center"
                      padding={1}
                      sx={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #575757',
                        borderRadius: '16px',
                        width: '50%',
                        '&:hover': {
                          opacity: 0.8,
                        }
                      }}
                      component="a"
                      href="tel:03-6281-7737"
                      onClick={() => {
                        if (typeof window.gtag === 'function') {
                          window.gtag('event', 'click_tel_reservation', {
                            event_category: 'button',
                            event_label: 'reservation_button',
                          });
                        }
                      }}
                    >
                      <SettingsPhoneIcon
                        sx={{
                          color: '#616161',
                          fontSize: '28px',
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: {
                            xs: '14px',
                            md: '16px',
                          },
                        }}
                      >
                        03-6281-7737
                      </Typography>
                    </Stack>

                    <Stack
                      display="flex"
                      justifyContent="center"
                      gap={1}
                      alignItems="center"
                      padding={1}
                      sx={{
                        backgroundColor: '#595c77',
                        border: '1px solid #575757',
                        borderRadius: '16px',
                        width: '50%',
                        '&:hover': {
                          opacity: 0.8,
                        }
                      }}
                      component="a"
                      target="_blank"
                      href="https://clinics-app.com/dental/c2be2993-6582-40bf-ba37-bcdcf6ff3ee2/exclusive_reservation"
                      onClick={() => {
                        if (typeof window.gtag === 'function') {
                          window.gtag('event', 'click_web_reservation', {
                            event_category: 'button',
                            event_label: 'reservation_button',
                          });
                        }
                      }}
                    >
                      <EventNoteIcon
                        sx={{
                          color: '#FFFFFF',
                          fontSize: '28px',
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          color: '#FFFFFF',
                          fontSize: {
                            xs: '14px',
                            md: '16px',
                          },
                        }}
                      >
                        24時間WEB予約
                      </Typography>
                    </Stack>



                  </Box>
                </Stack>
              </Box>

            </Stack>

            <Card
              sx={{
                margin: "0 auto",
                padding: "16px 8px",
                textAlign: "center",
                border: "solid 2px rgb(150, 155, 178)",
                borderRadius: '16px',
                width: '100%',
              }}
            >
              <Stack
                gap="10px"
              >
                <Typography
                  fontSize="20px"
                  color="#757575"
                >
                  お支払い方法
                </Typography>

                <Stack
                  direction="row"
                  gap="20px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box
                    width="50%"
                    maxWidth="250px"
                  >
                    <img
                      src="/home/payment.jpg"
                      style={{
                        width: '100%'
                      }}
                    />
                  </Box>
                  <Box
                    flex="1"
                  >
                    <Typography
                      color="#757575"
                      textAlign="left"
                      sx={{
                        fontSize: {
                          xs: '14px',
                          md: '19px',
                        },
                      }}
                    >
                      セミセルフ精算機にて<br />
                      ・現金<br />
                      ・クレジットカード<br />
                      ・交通系電子マネー<br />
                      ・iD<br />
                      ・QUICPay<br />
                      ・PayPay<br />
                      ・デンタルローン<br />
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Stack>
      </Stack>
    </Stack >
  )
}

export default Information;