import { Stack, Typography } from "@mui/material"

type PropType = {
  imagePath: string;
  title: string;
  body: JSX.Element;
}

const Kodawari = (props: PropType) => {
  const {
    imagePath,
    title,
    body,
  } = props;

  return (
    <Stack
      sx={{
        background: '#FFFFFF',
        width: {
          xs: '100%',
          sm: 'calc(50% - 8px)',
        }
      }}
    >
      <Stack
        sx={{
          backgroundImage: `url(${imagePath})`,
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          height: '250px',
        }}
      >
      </Stack>
      <Stack
        display="flex"
        gap={2}
        padding={2}
      >
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{
            color: 'rgba(0, 0, 0, 0.87)'
          }}
        >
          {title}
        </Typography>
        <Typography variant="body2" textAlign="left">
          {body}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default Kodawari