import TrainIcon from '@mui/icons-material/Train';
import PaymentIcon from '@mui/icons-material/Payment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ComputerIcon from '@mui/icons-material/Computer';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ScannerIcon from '@mui/icons-material/Scanner';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import ContactsIcon from '@mui/icons-material/Contacts';

import { Box, Container, Divider, Stack, Typography } from '@mui/material'
import { CircleDescription } from '@/components/ui/CircleDescription'
import { Calendar } from '@/components/ui/Calendar';
import { Link } from 'react-router-dom';
import { DigitalCard } from '@/components/ui/DigitalCard';
import { SubjectCard } from '@/components/ui/SubjectCard';
import { Title } from "@/components/ui/Title";
import { useEffect, useState } from 'react';
import { ApiService } from '@/service/api';
import { Notice } from '@/service/model';
import { NoticeList } from '@/components/NoticeList';
import TrackingButtonInsta from '@/components/ui/TrackingButtonInsta';
import TrackingButtonYoyaku from '@/components/ui/TrackingButtonYoyaku';
import Information from '@/components/Information';
import Kodawari from '@/components/Kodawari';

function Home() {
  const [noticeList, setNotiseList] = useState<Notice[]>([])

  useEffect(() => {
    ApiService.getNoticeTopList().then((list) => {
      setNotiseList(list);
    }).catch(() => {

    });
  }, []);

  return (
    <>
      <TrackingButtonInsta />
      <TrackingButtonYoyaku />
      <Stack
        position="relative"
        display="flex"
        justifyContent="center"
        sx={{
          backgroundImage: 'url(/photo01.jpg)',
          height: '600px',
          backgroundSize: 'cover',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          backgroundBlendMode: 'lighten',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
        >
          <Stack
            maxWidth="350px"
            paddingX={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={4}
            sx={{
              marginBottom: '32px',
              paddingBottom: '32px',
              width: {
                xs: '100%',
                lg: '60%',
              },
              marginTop: {
                xs: '-120px',
                md: 0,
              }
            }}
          >
            <Typography
              variant="subtitle1"
              component="h1"
              color="rgba(0, 0, 0, 0.7)"
              sx={{
                fontSize: '14px',
                textAlign: 'center',
              }}
            >
              御縁を大切に。一人ひとりを丁寧に。
            </Typography>

            <Box
              sx={{
                margin: '-50px 0 0',
                display: {
                  xs: 'none',
                  lg: 'block',
                }
              }}
            >
              <img
                src="/pc-logo.png"
                alt="御茶ノ水つばめ歯科・矯正歯科"
                style={{ width: '100%', display: 'block' }}
              />
            </Box>

            <Box
              sx={{
                width: {
                  xs: '130px',
                  md: '150px',
                },
                display: {
                  xs: 'block',
                  lg: 'none',
                },
              }}
            >
              <img
                src="/logo-gray.png"
                alt="御茶ノ水つばめ歯科・矯正歯科"
                style={{ width: '100%', display: 'block' }}
              />
            </Box>
            <Box
              sx={{
                display: {
                  xs: 'block',
                  lg: 'none',
                }
              }}
            >
              <img
                src="/home/top-text.png"
                alt="御茶ノ水つばめ歯科・矯正歯科"
                style={{ width: '100%', display: 'block', opacity: 0.7 }}
              />
            </Box>
          </Stack>
        </Box>

        <Box
          position="absolute"
          bottom="16px"
          justifyContent="center"
          width="100%"
          sx={{
            display: 'flex',
            gap: {
              xs: 1,
              md: 3,
            }
          }}
        >
          <CircleDescription
            icon={<TrainIcon color="secondary" fontSize="large" />}
            primaryText={(
              <>
                複数路線
                <br />
                通院可能
              </>
            )}
            secondaryText={(
              <>
                御茶ノ水駅、神保町駅
                <br />
                小川町駅、淡路町駅
              </>
            )}
          />
          <CircleDescription
            icon={<PaymentIcon color="secondary" fontSize="large" />}
            primaryText={(
              <>
                各種決済対応
              </>
            )}
            secondaryText={(
              <>
                クレジットカード
                <br />
                電子マネー
                <br />
                QRコード
              </>
            )}
          />
          <CircleDescription
            icon={<AccessTimeIcon color="secondary" fontSize="large" />}
            primaryText={(
              <>
                好きな時間に
                <br />
                WEB予約
              </>
            )}
            secondaryText={(
              <>
                ・朝8時開始(水・木)
                <br />
                ・夜20時終了(火・金)
              </>
            )}
          />
        </Box>
      </Stack >

      <Information />

      <Stack
        display="flex"
        gap={3}
        padding={3}
        sx={{
          backgroundImage: 'url(/background01.jpg)',
          backgroundSize: 'cover',
        }}
      >

        <Calendar
          id="medical_hours"
        />
      </Stack>

      <Container maxWidth="xl" style={{ padding: 0 }}>

        <Stack>
          <NoticeList
            list={noticeList}
          />
          <Box
            display="flex"
            justifyContent="end"
            paddingRight={2}
            paddingTop={1}
            paddingBottom={2}
          >
            <Link to="/notice">
              <Typography
                variant="body1"
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                    color: '#06c',
                  }
                }}
              >
                お知らせ一覧へ
              </Typography>
            </Link>
          </Box>
          <Divider />
        </Stack>

        {/*
        <Stack
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
          marginY={2}
          padding={4}
        >
          <Typography
            variant="subtitle1"
            component="p"
            sx={{
              textAlign: 'center',
            }}
          >
            御縁を大切に。一人ひとりを丁寧に。をテーマに、デジタル診療に力を入れている歯科医院です。 矯正やインプラント、セラミック治療に対してデジタル治療を組み合わせ治療精度の向上を図ります。 複数の治療を組み合わせる場合には見積書の方に、大切な御友人を紹介頂いた方には双方に特典をおつけしますのでお気軽に御相談下さい。 一般保険診療全般から小児歯科治療、親知らずの抜歯などの口腔外科領域、根管治療、セラミック、インプラント、ワイヤー矯正、マウスピース矯正など幅広く対応いたします。
            <br /><br />
            スタッフ一同、ご来院を心よりお待ちしております。
          </Typography>

          <Typography variant="subtitle1" component="p">
            院長
          </Typography>
        </Stack>
        */}

        <Stack>
          <img src="/home/digital_data.png" alt="デジタルデータお渡ししています" />

          <Box
            p={4}
          >
            <Typography
              variant="body1"
              fontSize="16px"
              lineHeight={1.5}
              textAlign="center"
            >
              治療の合間に歯並びのデータを無料でお渡ししております。
              ご希望の方はスタッフにお伝えください。
              <br />
              参考データは
              <a
                href="https://tkminiapp.dental3dcloud.com/pagesOralReport/index?&id=32abb619-453c-5286-9db6-b34494c4faa3"
                style={{ textDecoration: 'underline' }}
                target="_blank">こちら</a>より。
            </Typography>
          </Box>
        </Stack>


        <Stack
          justifyContent="center"
          alignItems="center"
          gap={2}
          padding="32px 8px 32px"
          sx={{
            backgroundColor: '#969bb2',
          }}
        >
          <Stack
            justifyContent="center"
            gap={2}
          >
            <Typography
              variant="h2"
              textAlign="center"
              sx={{
                color: '#FFFFFF',
                fontSize: {
                  xs: '24px',
                  md: '40px',
                }
              }}
            >
              御茶ノ水つばめ歯科・矯正歯科
            </Typography>

            <Typography
              variant="h2"
              textAlign="center"
              sx={{
                color: '#FFFFFF',
                fontSize: {
                  xs: '20px',
                  md: '38px',
                },
                paddingBottom: '6px',
              }}
            >
              治療へのこだわり
            </Typography>
          </Stack>

          <Box
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            paddingY={2}
            maxWidth="800px"
            sx={{
              gap: {
                xs: 1,
                sm: 1,
              }
            }}
          >
            <Kodawari
              imagePath="/home/image09.jpg"
              title="患者様との対話を大切に"
              body={<>歯の治療は、患者様にとって見えにくく、何が行われているのか理解しづらいことが多いと思います。当院では、マイクロスコープの写真や口腔内スキャナーによる3D画像を用いて、歯の状態や治療方針を丁寧に説明し、患者様が納得された後に治療を進めるよう心がけております。治療中に不明点や不安なことがあれば、いつでもご説明いたしますので、遠慮なくご質問ください。</>}
            />

            <Kodawari
              imagePath="/home/image08.jpg"
              title="メタルフリーの歯並びへ"
              body={<>金属アレルギーのリスクを考慮し、いわゆる「銀歯」を用いた治療は積極的におすすめしておりません。  保険診療の範囲内においても、できる限り白い被せ物（レジンやCAD/CAM冠)での治療をご提案しております。さらにセラミックやジルコニアといった、より優れた強度と審美性を持つ素材（自由診療）もご用意しております。 </>}
            />

            <Kodawari
              imagePath="/home/image10.jpg"
              title="ハイブリット矯正治療"
              body={<>患者様一人ひとりのお口の状態に最適な矯正治療をご提供するため、マウスピース矯正とワイヤー矯正を組み合わせた「ハイブリッド矯正」を採用しております。
                それぞれの矯正方法のメリットを活かし、デメリットを補い合うことで、治療中の見た目と治療効果、そのどちらも重視したい方におすすめの治療法です。
                患者様が安心して、快適に矯正治療を続けられるよう寄り添いながら診療いたします。矯正治療中の虫歯治療も当院にお任せください。</>}
            />

            <Kodawari
              imagePath="/home/image11.jpg"
              title="家族みんなで通える歯医者さん"
              body={<>お子様の乳歯が生えた頃からのフッ素塗布から、働き盛り世代の定期検診や歯周病ケア、そしてシニア世代の入れ歯やインプラント治療まで、あらゆる年代のあらゆるお口のお悩みに対応しております。
                ベビーカーのまま診療室までご来院可能です。キッズスペースも完備しており、待ち時間やお子様連れの患者様の治療中にも、自由にご利用いただけます。 ご家族皆様が健康な歯で過ごせるようスタッフ一同、心を込めてサポートいたします。 </>}
            />

            <Kodawari
              imagePath="/home/image12.jpg"
              title="美しい歯の追求"
              body={<>虫歯や歯周病の治療はもちろん、歯本来の美しさを引き出す診療にも力を入れています。ただ単に治療するだけでなく、患者様が自信を持って笑えるようお手伝いをさせていただきます。医療ホワイトニングで白い歯に、ダイレクトボンディングやセラミックを用いて自然な歯の仕上がりを目指します。削る量をできるだけ抑え、見た目の改善を図るため、一つ一つの歯を丁寧に治療いたします。</>}
            />

            <Kodawari
              imagePath="/home/image13.png"
              title="親知らずの抜歯"
              body={<>親知らずの抜歯が必要だと理解しているものの、つい後回しにしている方や、大学病院などの大きな施設での抜歯に不安を感じている方は、是非ご相談ください。CTを撮影しリスクを確認した上で、適切な対応をいたします。状況が良ければ、ご希望に応じて初診時に抜歯を行うことも可能です。また、矯正治療に伴う便宜抜歯も当院で対応しています。</>}
            />

            <Kodawari
              imagePath="/home/image14.png"
              title="インプラント治療"
              body={<>インプラント治療は入れ歯やブリッジに代わる治療法として注目されていますが、メリットだけでなく、いくつかのデメリットがあることも事実です。 そのため、当院では事前に詳細な検査を行い、歯や骨の状態を確認してインプラント治療が適切かどうかを判断します。また、インプラント以外の治療法（入れ歯やブリッジ、矯正治療など）についてもご説明し、患者様にとって最も適した治療法を一緒に考えていきます。</>}
            />

            <Kodawari
              imagePath="/home/image15.jpg"
              title="充実した歯科医療設備とデジタル化"
              body={<>患者様に満足いただける治療を提供するため、様々な歯科医療機器やデジタル機器を導入しています。
                (マイクロスコープ、口腔内スキャナー、口腔内カメラ、歯科用CT、セラミック切削用ミリングマシン、リラクゼーションチェアー、自動洗浄機、セミセルフ精算機、24時間web予約システム、オンライン治療相談対応など。)</>}
            />

          </Box>

        </Stack>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
          marginY={2}
        >
          <div style={{
            position: 'relative',
            width: '100%',
            paddingTop: '56.25%' /* 16:9 Aspect Ratio */
          }}>
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              src="https://www.youtube.com/embed/AIFlrbBP7oc?si=ezzqIyz_mCdL7QGd"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              loading="lazy"
            />
          </div>
        </Box>

        <Stack
          display="flex"
          gap={2}
          marginY={4}
        >
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              color: 'rgba(0,0,0,0.67)',
              fontWeight: 700,
              border: 'solid 1px #000',
              borderRadius: '20px',
              width: 'fit-content',
              margin: '24px auto',
              padding: 2,
              fontSize: {
                xs: '20px',
                md: '32px',
              }
            }}
          >
            デジタル診療に特化した医院
          </Typography>

          <Box
            display="flex"
            justifyContent="center"
            gap={2}
            flexWrap="wrap"
          >
            <DigitalCard
              icon={ComputerIcon}
              title="カルテの電子化"
              text="カルテの電子化により情報の検索、共有を素早く行うことが出来ます。以前の治療内容を確認し易くなり、久々の電話予約でもすぐに検索できますのでお待たせする時間が短くなります。"
            />

            <DigitalCard
              icon={SmartphoneIcon}
              title="予約のデジタル化"
              text="24時間予約をお取り頂けるよう、このHPより予約ページに移行出来るようになっております。事前に質問内容を記入いただけますので治療の際がスムーズにご案内が可能となります。"
            />

            <DigitalCard
              icon={ScannerIcon}
              title="型取りは口腔内スキャナーで"
              text="自費診療の歯型取りは口腔内スキャナーで行います。セラミック、インプラント、マウスピース矯正、マウスガードなど。苦手な方も多い印象材での型取りはなるべく行いません。"
              alert="＊保険治療の場合、算定項目としてデジタル印象がない場合は従来通りの型取りとなります。"
            />

            <DigitalCard
              icon={PhonelinkIcon}
              title="デジタル同士の組み合わせ"
              text="３次元のCTレントゲン×インプラントのガイドドリル。CT×矯正ブラケットのデジタルセットアップ。口腔内スキャナー×マウスピース矯正orセラミックミリングなど。デジタル器具同士で相性の良い治療を多く取り入れています。"
            />

            <DigitalCard
              icon={ContactsIcon}
              title="オンライン相談にも対応"
              text="直接歯科医院まで来る時間が取れない方や遠方の方向けに、予約ページからオンライン治療相談の予約を取る事が出来ます。新しい試みですので、使用頂き易いように都度更新を行なっていく予定です。"
            />

            <DigitalCard
              icon={PaymentIcon}
              title="セルフ精算システム"
              text="スタッフの金額入力ミスやお釣りの間違いをなくし、スムーズな会計が行えます。各種お支払い方法が選べます。"
            />

          </Box>


        </Stack>

        <Stack
          sx={{
            backgroundImage: 'url(/home/image06.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: '50%',
            height: '400px',
            opacity: 0.8,
          }}
        ></Stack>

        <Stack
          marginY={4}
          component="section"
          id="medical_subject"
        >
          <Title title="診療科目" />

          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            gap={2}
            paddingY={4}
            paddingX={2}
            sx={{
              backgroundColor: '#EEEEEE',
            }}
          >
            <SubjectCard
              src="/home/subject01.svg"
              title="一般歯科治療"
              text="虫歯の治療、根管治療、歯周病治療、顎関節症の対応など"
              to="https://blog.swallow-dental.com/category/cavity-treatment/"
            />

            <SubjectCard
              src="/home/subject02.svg"
              title="予防歯科"
              text="歯で困る事が少なくなるように予防治療に力を入れています。"
              to="https://blog.swallow-dental.com/category/preventive-dentistry/"
            />

            <SubjectCard
              src="/home/subject03.svg"
              title="小児歯科"
              text="お子様が健やかに成長できるよう寄り添いながら治療を行います。"
              to="https://blog.swallow-dental.com/category/pediatric-dentistry/"
            />

            <SubjectCard
              src="/home/subject04.svg"
              title="ホワイトニング"
              text="歯科専用の薬品を用いてホワイトニングを行います。"
              to="https://blog.swallow-dental.com/category/whitening/"
            />

            <SubjectCard
              src="/home/subject05.svg"
              title="親知らずの抜歯"
              text="歯肉の腫れや口臭の原因になる場合は抜歯をおすすめします。"
              to="https://blog.swallow-dental.com/category/oral-surgery/"
            />

            <SubjectCard
              src="/home/subject06.svg"
              title="インプラント"
              text="歯を失ってしまった箇所に人工の歯根を埋め込み、歯を補う治療法です。"
              to="https://blog.swallow-dental.com/category/implant/"
            />

            <SubjectCard
              src="/home/subject07.svg"
              title="歯列矯正"
              text="ワイヤーやマウスピースを使用し全体の歯並びを改善します。"
              to="https://blog.swallow-dental.com/category/orthodontic-treatment/"
            />

            <SubjectCard
              src="/home/subject08.svg"
              title="セラミック治療"
              text="銀歯をなくしたり、色のあった被せ物を作製します。"
              to="https://blog.swallow-dental.com/category/aesthetic/"
            />

            <SubjectCard
              src="/home/subject09.svg"
              title="マウスピース"
              text="歯ぎしり用、顎関節症改善用、スポーツ用のマウスピースなど。"
              to="https://blog.swallow-dental.com/category/mouthpiece/"
            />

            <SubjectCard
              src="/home/subject10.svg"
              title="入れ歯"
              text="自然な見た目と快適な装着感で、毎日の食事も笑顔もより豊かに。"
              to="https://blog.swallow-dental.com/category/denture/"
            />

          </Box>
        </Stack>

        <Stack
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
          marginY={2}
        >
          <div style={{
            position: 'relative',
            width: '100%',
            paddingTop: '56.25%' /* 16:9 Aspect Ratio */
          }}>
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              src="https://www.youtube.com/embed/6ILNAkFjvVE?si=OqYCp-MjT6-nNesl"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              loading="lazy"
            />
          </div>

          <div style={{
            position: 'relative',
            width: '100%',
            paddingTop: '56.25%' /* 16:9 Aspect Ratio */
          }}>
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              src="https://www.youtube.com/embed/ZXQ8Kc4tHZI?si=kZsgDAb95PPr2rqd"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              loading="lazy"
            />
          </div>
        </Stack>


        <Stack
          marginY={4}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h4" // タイトル用の大きなフォントを指定
            textAlign="center"
            paddingX={2}
            sx={{
              lineHeight: 1.5,
              letterSpacing: '2px',
              color: '#414141',
              fontSize: '24px', // タイトル用の大きなフォントサイズ
              marginBottom: 4, // 心地よい歯科医院を創るの下にスペースを追加
            }}
          >
            「また来たい」と思える、心地よい歯科医院をめざして
          </Typography>

          <Typography
            variant="body2"
            textAlign="center"
            paddingX={2}
            sx={{
              maxWidth: '860px',
              lineHeight: 2.5,
              letterSpacing: '2px',
              color: '#414141',
              fontSize: '16px',
            }}
          >
            「御茶ノ水つばめ歯科・矯正歯科」院長の長谷川陽一と申します。
            <br /><br />
            昭和大学歯学部を卒業後、都内の複数の歯科医院に勤務し、
            一般歯科治療をはじめ、インプラント、矯正治療、審美治療など幅広い分野の診療に携わりました。
            <br /><br />
            様々な症例を経験する中で、歯科医師としての知識・技術を磨くうちに、
            患者様一人ひとりの氣持ちに寄り添った治療の必要性を強く感じるようになりました。
            <br /><br />
            幸運の象徴とも言われる「つばめ」のように、当院が皆様にとって安心と幸せを運ぶ場所でありたい。
            そんな想いを込めて、2024年6月に「つばめ歯科」を開院いたしました。
            <br /><br />
            歯の健康は、人生の幸福度に大きく影響します。
            私たちは、皆様が生涯にわたって健康で快適な生活を送れるよう、
            歯の治療を通してお手伝いをいたします。
            <br /><br />
            一人ひとりの患者様とのご縁を大切にし、
            丁寧なカウンセリングと治療を通じて、皆様に心から信頼していただけるよう努めてまいります。
            <br /><br />
            また、治療後も定期的なメンテナンスを通じて、皆様の歯の健康を末永く見守り、
            笑顔あふれる毎日を過ごしていただくことが私たちの願いです。
            <br /><br />
            どんな些細なお悩みでも、どうぞお気軽にご相談ください。
            スタッフ一同、心を込めて対応させていただきます。
            <br /><br />
            御茶ノ水つばめ歯科・矯正歯科 院長 / 歯科医師 長谷川 陽一 (昭和大学歯学部卒)

            <small
              style={{
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.47)',
                display: 'inline-block',
                marginLeft: '4px',
              }}
            >Yoichi Hasegawa</small>
          </Typography>
          <Stack
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <img
              src="/home/image04.jpg"
              style={{
                display: 'block',
                maxWidth: "450px",
                width: "100%",
              }}
            />
          </Stack>

          <Box
            display="flex"
            justifyContent="center"
            gap={2}
            mt={4}
            flexWrap="wrap"
          >
            <a href="https://doctorsfile.jp/h/218253/df/1/" target="_blank">
              <img style={{ width: '250px' }} src="/banner/banner_001.jpg" />
            </a>
            <a href="https://shikamedi.com/2024/08/21/orthodontics/" target="_blank">
              <img style={{ width: '250px' }} src="/banner/banner_004.png" />
            </a>

            <a href="https://lit.link/tsubamedc" target="_blank">
              <Stack
                height="100px"
                width="250px"
                alignItems="center"
                justifyContent="center"
                sx={{
                  background: '#8a8a8a',
                }}
              >
                <img style={{ width: '250px' }} src="/banner/sns_banner.png" />
              </Stack>
            </a>

          </Box>

        </Stack>

        {/*
        <Stack marginY={4}>
          <Access />
        </Stack>
        */}
        {/*
        <Box
          marginY={4}
          display="flex"
          justifyContent="center"
          gap={3}
        >

          <Stack
            display="flex"
            justifyContent="center"
            gap={1}
            alignItems="center"
            padding={1}
            sx={{
              backgroundColor: '#595c77',
              border: '1px solid #575757',
              borderRadius: '16px',
              width: '300px',
              maxWidth: 'calc(50% - 16px)',
              '&:hover': {
                opacity: 0.8,
              }
            }}
            component="a"
            target="_blank"
            href="https://clinics-app.com/dental/c2be2993-6582-40bf-ba37-bcdcf6ff3ee2/exclusive_reservation"
            onClick={() => {
              if (typeof window.gtag === 'function') {
                window.gtag('event', 'click_web_reservation', {
                  event_category: 'button',
                  event_label: 'reservation_button',
                });
              }
            }}
          >
            <EventNoteIcon
              sx={{
                color: '#FFFFFF',
                fontSize: '28px',
              }}
            />
            <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
              WEBで予約
            </Typography>
          </Stack>


          <Stack
            display="flex"
            justifyContent="center"
            gap={1}
            alignItems="center"
            padding={1}
            sx={{
              backgroundColor: '#FFFFFF',
              border: '1px solid #575757',
              borderRadius: '16px',
              width: '300px',
              maxWidth: 'calc(50% - 16px)',
              '&:hover': {
                opacity: 0.8,
              }
            }}
            component="a"
            href="tel:03-6281-7737"
            onClick={() => {
              if (typeof window.gtag === 'function') {
                window.gtag('event', 'click_tel_reservation', {
                  event_category: 'button',
                  event_label: 'reservation_button',
                });
              }
            }}
          >
            <SettingsPhoneIcon
              sx={{
                color: '#616161',
                fontSize: '28px',
              }}
            />
            <Typography variant="body1">
              電話予約
            </Typography>
          </Stack>
        </Box>
        */}
      </Container >
    </>
  )
}

export default Home
